import React from "react"

const Ease = () => {
  const email = "ease@wonderhorseequestrian.com"

  return (
    <div className="flex items-center flex-col p-6">
      <div>
        <img src="/images/whe-logo.png" alt="Wonder Horse EASE logo" />
      </div>
      <div className="max-w-sm text-center mb-8">
        <h4>
          Equine Assisted Service Education that is easy, accessible,
          supportive, and empowering.
        </h4>
      </div>
      <div>
        <a
          className="inline-block w-full md:inline text-center md:w-min px-8 py-2 text-gy-0 rounded transition hover:bg-b-900 hover:shadow-lg no-underline bg-b-700"
          href="https://members.wonderhorseease.com"
        >
          <span className="text-sm text-b-100 uppercase tracking-wider">
            Learn More
          </span>
        </a>
      </div>
      <div className="mt-6 text-center">
        <span className="text-sm block mb-0">
          For questions or more information contact
        </span>
        <a
          className="text-sm md:text-base text-b-700 no-underline hover:underline"
          href={`mailto:${email}`}
        >
          {email}
        </a>
      </div>
    </div>
  )
}

export default Ease
